export function common() {
  $(function () {
    new ScrollHint('.js-scrollable');
  });

  $(window).on('load', function () {
    let headerHeight = $('.l-header').outerHeight();
    let urlHash = location.hash;
    if (urlHash) {
      let position = $(urlHash).offset().top - headerHeight - 50;
      $('html, body').animate({
        scrollTop: position
      }, 0);
    }
  });

  $(window).on('load', function () {
    $('.p-top-news__time.new').closest('.p-top-news__link').addClass('new-active');
    $('.p-news__time.new').closest('.p-news__link').addClass('new-active');
  });

  $(window).on('load', function () {
    $('.video-open').modaal({
      type: "video",
    });
  });

  $(window).on('load', function () {
    const pageTop = $("#page-top");
    pageTop.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        pageTop.fadeIn();
      } else {
        pageTop.fadeOut();
      }
    });
    pageTop.click(function () {
      $("body,html").animate({
          scrollTop: 0,
        },
        0
      );
      return false;
    });
  });

  $(function () {
    $('a.tab-link').on('click', function (e) {
      var url = $(this).attr("href");
      window.location.href = url;
      location.reload(true);
    });
  });

  $(function () {
    $(".checkbox-disabled").prop("disabled", true);
  });
}
