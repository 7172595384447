export function inputErrorCheck(formEl) {
  $(formEl).each(function () {
    $('span.error').closest('.p-general-inquiry-form__input-wrap').addClass('p-general-inquiry-form__input-wrap--caution');
    $('span.error').parent('.p-entry__input-wrap').addClass('p-entry__input-wrap--caution');
    $('.p-entry__inner .error').prev('.p-entry__select-wrap').addClass('p-entry__select-wrap--caution');
  });
}

export function inputAutofill() {
  $('#zip-button').on('click', function () {
    AjaxZip3.zip2addr('zip-code', '', 'address', 'address');

    //成功時に実行する処理
    AjaxZip3.onSuccess = function () {
      $('.addr').focus();
    };

    //失敗時に実行する処理
    AjaxZip3.onFailure = function () {
      alert('郵便番号に該当する住所が見つかりません');
    };

    return false;
  });
}
