export function tab(tabButtonEl, tabContentsEl) {
  if ($(tabButtonEl).length && $(tabContentsEl).length) {
    $(tabButtonEl).children().on("click", function () {
      const index = $(tabButtonEl).children().index(this);
      $(tabButtonEl).children().removeClass("active");
      $(tabContentsEl).children().removeClass("active");
      $(this).addClass("active");
      $(tabContentsEl).children().eq(index).addClass("active");
    });
  }
}

export function tabLink(tabLinkButton, tabContentsEl) {
  if ($(tabLinkButton).length && $(tabContentsEl).length) {

    var hash = window.location.hash;
    if (hash) {
      $(hash).addClass("active");
      $('a[href="' + hash + '"]').parent().addClass('active');
    }

    $(tabLinkButton).children().on("click", function () {
      const index = $(tabLinkButton).children().index(this);
      $(tabLinkButton).children().removeClass("active");
      $(tabContentsEl).children().removeClass("active");
      $(this).addClass("active");
      $(tabContentsEl).children().eq(index).addClass("active");
    });
  }
}
