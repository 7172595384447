export function accordion(accordionEl, accordionButtonEl, accordionItemEl) {
  if ($(accordionEl).length && $(accordionButtonEl).length && $(accordionItemEl).length) {
    $(accordionEl).on("click", function () {
      $(this).children(accordionButtonEl).toggleClass("active");
      $(this).next($(accordionItemEl)).slideToggle();
    });
  }

  $(function () {
    const urlHash = location.hash;
    if (!$(urlHash).length) return;

    $(urlHash).find('.accordionButton').addClass('active');
    $(urlHash).find('.accordion').next().show();
  });
}

export function hamburgerAccordion() {
  $(".p-hamburger__accordion").on("click", function () {
    $(this).toggleClass("p-hamburger__accordion-active");
    $(this).children(".p-hamburger__accordion-cross").toggleClass("active");
    var findElm = $(this).next(".p-hamburger__accordion-wrap");
    $(findElm).slideToggle();
  });
}
