import Swiper from "swiper/bundle";
import "swiper/css/bundle";

export function slider() {
  // topPage slider
  const swiper1 = new Swiper('.swiper-top', {
    direction: "horizontal",
    loop: true,
    allowTouchMove: false,
    speed: 2000,
    autoplay: {
      delay: 8000,
      disableOnInteraction: false
    },

    pagination: {
      el: ".p-top-kv__swiper-pagination",
    },
  });

  // topics slider pc
  $(document).ready(function () {
    var clonedElement = $('.topics-swiper .swiper-slide').clone();
    $('.topics-swiper .swiper-wrapper').append(clonedElement);
  });

  const swiper2 = new Swiper(".topics-swiper", {
    loop: true,
    slidesPerView: 'auto',
    loopedSlides: 2,
    initialSlide: 1,
    speed: 1000,
    spaceBetween: 30,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    pagination: {
      el: ".p-top-news__topics-pagination",
      type: "fraction",
      formatFractionCurrent: function (number) {
        if (number - 1 == 0) {
          var lastSlideNumber = $(".topics-swiper .swiper-slide").length / 2;
          // return $(".topics-swiper .swiper-slide").length;
          return lastSlideNumber;
        } else {
          return number - 1;
        }
      },
      formatFractionTotal: function (number) {
        return number / 2;
      }
    },

    navigation: {
      nextEl: ".p-top-news__topics-navigation-next",
      prevEl: ".p-top-news__topics-navigation-prev",
    },
  });

  // topics slider sp
  const topicsSliderSP = new Swiper(".p-top-news__topics-swiper-sp", {
    loop: true,
    slidesPerView: 'auto',
    spaceBetween: 20,
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: ".p-top-news__topics-navigation-next-sp",
      prevEl: ".p-top-news__topics-navigation-prev-sp",
    },
  });

  // recruit slider sp
  const swiper3 = new Swiper(".p-recruit-people__swiper-sp", {
    loop: true,
    spaceBetween: 20,
    slidesPerView: '1.14',
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: ".p-recruit-people__navigation-next",
      prevEl: ".p-recruit-people__navigation-prev",
    },
  });

  // recruit slider pc
  const swiper4 = new Swiper(".p-recruit-people__swiper-pc", {
    loop: true,
    slidesPerView: '3',
    speed: 1000,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },

    navigation: {
      nextEl: ".p-recruit-people__navigation-next",
      prevEl: ".p-recruit-people__navigation-prev",
    },
  });
}
