import {
  common
} from "./components/common";
import {
  hamburgerMenuButton
} from "./components/hamburgerMenuButton";
import {
  slider
} from "./components/slider";
import {
  hamburgerAccordion,
  accordion
} from "./components/accordion";
import {
  sidebarMenu
} from "./components/sidebarMenu";
import {
  tab,
  tabLink
} from "./components/tab";
import {
  inputErrorCheck,
  inputAutofill
} from "./components/inputError";
import 'modaal/dist/js/modaal.min.js';
import 'modaal/dist/css/modaal.min.css';

$(document).ready(function () {
  (() => {
    common();
  })();

  (() => {
    /*
    ハンバーガーメニュー用関数
    */
    //ハンバーガーメニューボタン定義
    const buttonEl = "#hamburgerButton";
    //ハンバーガーメニュー定義
    const menuEl = "#hamburgerMenu";
    hamburgerMenuButton(buttonEl, menuEl);
  })();

  (() => {
    /*
    スライダー用関数
    */
    slider();
  })();

  (() => {
    /*
    アコーディオン用関数
    */
    //アコーディオンラッパー定義
    const accordionEl = ".accordion";
    //アコーディオンボタン定義
    const accordionButtonEl = ".accordionButton";
    //アコーディオンアイテム定義
    const accordionItemEl = ".accordionItem";

    accordion(accordionEl, accordionButtonEl, accordionItemEl);
    hamburgerAccordion();
  })();

  (() => {
    /*
    サイドメニュー用関数
    */
    sidebarMenu();
  })();

  (() => {
    /*
    タブ用関数
    */
    //タブボタンラッパークラス定義
    const tabButtonEl = ".tabButton";
    //タブコンテンツラッパークラス定義
    const tabContentsEl = ".tabContent";
    tab(tabButtonEl, tabContentsEl);

    const tabLinkButton = ".tabLinkButton";
    tabLink(tabLinkButton, tabContentsEl);
  })();

  (() => {
    /*
    フォームバリデーション用関数
    */
    //フォームラッパークラス定義
    const formEl = ".p-form";
    //WPなどでフォームから出力されるエラークラスを定義
    const errorEl = "error";
    inputErrorCheck(formEl);
    inputAutofill();
  })();
});
