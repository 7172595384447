export function sidebarMenu() {
  $(".l-header-sidebar__menu-item").hover(
    function () {
      $(this).addClass('is-active');
      $(this).find('.l-header-sidebar__sub-menu-wrap').addClass('is-open');
      $(this).find('.l-header-sidebar__sub-menu-cross').addClass('is-active');

    },
    function () {
      $(this).removeClass('is-active');
      $(this).find('.l-header-sidebar__sub-menu-wrap').removeClass('is-open');
      $(this).find('.l-header-sidebar__sub-menu-cross').removeClass('is-active');
    }
  );
}
